import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/DefaultPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Designing a Type-Safe Asynchronous Data Loading Pattern with React and MobX`}</h1>
    {
      /* cut */
    }
    <p>{`Asynchronous data loading is a pervasive challenge in modern web development.
When using React and MobX, the complexity can quickly escalate, giving rise to questions about type safety, error handling, and boilerplate code.
In this article, we won't just talk about these challenges; we'll solve them.`}</p>
    <p>{`Through a targeted design session, we will dissect the problem and build a type-safe and efficient solution for asynchronous data loading in React and MobX applications.`}</p>
    <p>{`Additionally, this article aims to offer more than just a solution; it aims to walk you through the thinking process involved in tackling a problem that lies at the intersection of technology and application design.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<!-- cut -->`}</code></pre></div>
    <h2>{`Problem statement`}</h2>
    <p>{`First and foremost, this article assumes you have a working knowledge of React, MobX, and TypeScript. If you're not well-versed in these technologies, don't fret; this article can still provide valuable insights into the application-level technical design process.`}</p>
    <p>{`Every effective design begins with a clear problem statement.`}</p>
    <p>{`In our case, the challenge lies in constructing a UI that interacts seamlessly with asynchronous processes. These are often encapsulated within promises, which frequently serve as wrappers for network operations like HTTP calls.`}</p>
    <p>{`Now, let's delve into the conventional workflow for handling asynchronous data loading in React and MobX applications.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useEffect `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` makeAutoObservable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` runInAction `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` observer `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx-react-lite'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// let say some response from API`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SomeData`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStore`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    isLoading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` SomeData `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	error`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`makeAutoObservable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// typical pattern in loading data`}</span>{`
		`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// start loading process`}</span>{`
		`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isLoading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
			`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'https://some-api.com'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
			`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`json`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
			`}<span parentName="code" {...{
            "className": "token function"
          }}>{`runInAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
				`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// if success - initialize data with response`}</span>{`
				`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
			`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
			`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// in case of error - show error`}</span>{`
			`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`error `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Unexpected error happened during loading data.'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`console`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`finally`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
			`}<span parentName="code" {...{
            "className": "token function"
          }}>{`runInAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
				`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// in all cases finish loading process`}</span>{`
				`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isLoading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
			`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` myStore `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` MyComponent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`observer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`useEffect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// orphan promise, most likely complaint of eslint`}</span>{`
        myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`fetchData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// now handle all the loading cases in component`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
		`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isLoading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Loading...`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`error `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
		`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`So, what are the key issues with the current approach?`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Orphan Promise`}</strong>{`: ESLint often flags the promise in useEffect as unhandled. Although we can disable the ESLint warning, the code doesn't make it explicit how errors within the promise are managed, introducing unnecessary cognitive load each time the component is reviewed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Boilerplate Overhead in Components and Stores`}</strong>{`: Handling loading states directly within each component and managing the async process in each MobX store can become cumbersome as the application scales. This leads to repetitive and fragmented code across both UI and state management layers.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Lax Error Handling`}</strong>{`: The manual handling of loading states doesn't enforce best practices in error handling. It's easy to overlook cases, leaving the user staring at a blank screen or a perpetual loading indicator.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Ambiguous Error Management`}</strong>{`: The existing code creates ambiguity about how to deal with errors—should they be logged to the console, displayed to the user, or handled in some other way? This again adds cognitive load to the development process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`MobX Async Complexity`}</strong>{`: The current pattern also exposes the inherent intricacies of dealing with asynchronous functions in MobX. For instance, one might forget to invoke `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`runInAction`}</code>{`, causing unintended behavior.`}</p>
      </li>
    </ol>
    <h2>{`Design goals`}</h2>
    <p>{`Now that we've pinpointed the issues with the existing setup, it's time to outline our design objectives. While these goals largely stem from our problem statement, articulating them explicitly adds clarity to our mission.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Type Safety`}</strong>{`: Our design should be type-safe to catch errors at compile time rather than at runtime, ensuring robustness. It means that we should make state that is not possible in our subject area impossible to represent in our types.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Error Handling`}</strong>{`: The approach must standardize error handling to provide a consistent user experience and ease developer cognitive load.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Boilerplate Minimization`}</strong>{`: The solution should aim to reduce boilerplate in both React components and MobX stores, streamlining code maintenance and scalability.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`MobX Async Abstraction`}</strong>{`: We aim to simplify the complexities tied to handling asynchronous functions in MobX, enhancing code readability and maintainability.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Framework Agnostic`}</strong>{`: Although our examples will primarily utilize React, the design should be sufficiently abstract to be applicable to other frameworks. This focus on MobX and TypeScript enables broader usability.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Additional Goals`}</strong>{`: The solution should help to build other patterns of async actions, like easy automatic or manual retries, cancellation and immutability where it is needed.`}</p>
      </li>
    </ol>
    <p><em parentName="p">{`NOTE: While some may argue that changing UI frameworks is a non-issue, real-world scenarios prove otherwise. At the time of writing this article, I'm work with a company where our website features pages built in almost every major framework—React, Vue 2/3, AngularJS, and Angular.`}</em></p>
    <h2>{`Investigation on ready-to-use solutions`}</h2>
    <p>{`Before we go into the design process, let's take a look at some existing solutions to see if they can meet our needs.`}</p>
    <ol>
      <li parentName="ol">
        <a href="https://react.dev/reference/react/Suspense" target="_blank" rel="noopener">React Suspense</a> - native way to do loading in React. It does not feet design goals in 2 dimensions. First of all it is not framework-agnostic, it could be applied only with React. Second, it does not enforce good practices of loading and error handling.
      </li>
      <li parentName="ol">
        <a href="https://tanstack.com/query/v3/docs/react/overview" target="_blank" rel="noopener">React Query</a> - a hook way to do loading process. Definitely do not fit goals, since it is tight to React and does not enforce good practices of loading and error handling.
      </li>
      <li parentName="ol">
        <a href="https://github.com/mobxjs/mobx-utils#frompromise" target="_blank" rel="noopener">mobx-utils.fromPromise</a> - well this a good one, really a good one. If you need ready-to-go option, I highly recommend to just use *mobx-utils.fromPromise* . But in the sake of demonstrating design process, we will not use it and design own similar thing. Also will add some minor improvements to fromPromise has, like immutability of properties and out-of-the-box retries.
      </li>
    </ol>
    <h2>{`Design process`}</h2>
    <p>{`Now that we've established our goals and investigated existing solutions, it's time to design our own solution. We'll start by outlining the high-level design and then dive into the details.`}</p>
    <p>{`We might start from jumping directly into mobx based primitive that looks like`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` makeAutoObservable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` action `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MobxAsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    error`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`unknown`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    isLoading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`makeAutoObservable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'resolve'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
				`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
				`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isLoading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
			`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
			`}<span parentName="code" {...{
            "className": "token function"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'reject'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`error `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isLoading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
			`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`While this might look like a good approach, it fails to meet design goals that were defined at the begging.
Try not to look at the code in the constructor, but rather concentrate on the type definition.
If we look close this state is possible from the type definition perspective:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` asyncData `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'some data'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	error`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'some error'`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This is not possible case if we are talking about async data loading, since we can not have data and error at the same time.`}</p>
    <p>{`So first of all lest start from basics and describe state of the loading in typescript terms. Which is close to model our domain.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// This is our success case when we have specific result of async action`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// we explicitly say`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Success`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`TData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Readonly`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This is our error case when we have error of async action`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Errored`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Readonly`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'error'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    error`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`unknown`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    errorMessage`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This is our loading case when we have no result of async action yet`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`LoadingInitiated`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Readonly`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'loading'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Unitiated`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Readonly`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'uninitiated'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// And finally we join them together`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// into discriminated union to represent all the possible states`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncState`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`TData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Success`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Errored `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` LoadingInitiated `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Unitiated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Discriminator (kind property) in union`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// gives ability to utilize typescript inference`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// in conditional statements to dispatch correct type`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// and users of the state mostly will be obligated to do that, i.e.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` state`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` AsyncState`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'some data'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// become available for usage and type is correctly inferred as string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`As you might see we define specific type for each possible state of async action and utilize discriminated union to represent all the possible states.`}</p>
    <p>{`Now we can create helpers to create data record of each type.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` receivedData `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Success`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` receivedError `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`unknown`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Errored `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'error'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	errorMessage`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` error `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`instanceof`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` error `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'string'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` error `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` newLoading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` LoadingInitiated `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'loading'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` uninitiated `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Unitiated `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	kind`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'uninitiated'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now when our domain is modelled in pure typescript types, we can apply it to next layer of abstraction - mobx entities.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` makeObservable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` runInAction`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` observable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` action `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`TData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`readonly`}</span>{` empty `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`TData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Cannot initiate empty AsyncData'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`observable`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ref
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` state`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` AsyncState`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`unitiated`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// we are passing callback here in order to be able to utilize callback later`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`readonly`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`loadCallback`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`makeObservable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// here we encapsulate orphaned promise,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// and it is safe since we know that all the errors are handled below`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`start`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`load`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// here we encapsulate standard async operation representation in any generic UI`}</span>{`
	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// with handling all the possible cases`}</span>{`
    `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`action`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`bound
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`load`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Cannot initiate already loaded AsyncData'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`newLoading`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`loadCallback`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`runInAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`receivedData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`runInAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`receivedError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// convenience method to bind loading states quickly`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`loading`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Perfect, it is something already. Now we can use it in our stores.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AsyncData `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./AsyncData'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useEffect `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` observable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` makeObservable `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` observer `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx-react-lite'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStore`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`observable`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ref
	myItems `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'https://some-api.com'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`json`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`makeObservable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` myStore `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` MyComponent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`observer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`useEffect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`myItems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`start`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` myItems `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` myStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// now handle all the loading cases in component`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
		`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myItems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`loading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Loading...`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myItems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'error'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myItems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`errorMessage `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`??`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Unexpected error'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myItems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`myItems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
		`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Well we have some primitives to work with and component look like it handles the different cases.`}</p>
    <p>{`Let's look at the design goals we set:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Type Safety`}</strong>{`: done. It now forces to check state before reaching to a data. Also, specific state is enforced.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Error Handling`}</strong>{`: not done yet, component itself looks like it was before.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Boilerplate Minimization`}</strong>{`: almost done, we do not have boilerplate in store anymore, but still a little in the component.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`MobX Async Abstraction`}</strong>{`: done. Async machinery works outside our code and user code works only with observables.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Framework Agnostic`}</strong>{`: also done since we used plain typescript and mobx.`}</p>
      </li>
    </ol>
    <p>{`While it is possible to create pattern on top of what we have now for handling all the states in component, I'll omit this part. You can use it as opportunity to model it further.
But demonstrate now what we can do with a good foundation we have now.`}</p>
    <p>{`Let's solve some of the additional goals, let say we want to have retries in our async action.
I'll demonstrate only changed parts.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncDataOptions`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// indicates that action could be users might try to retry operation if it is failed`}</span>{`
    manuallyRetriable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`TData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ... other code`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`loadCallback`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` AsyncDataOptions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// we have added options`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`manuallyRetriable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?.`}</span>{`manuallyRetriable `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`??`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`action`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`bound
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`load`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`manuallyRetriable `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'success'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Cannot initiate already loaded AsyncData. If it should be possible - please indicate that it can be retried manually'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ... other function code`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ... class other code`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, with our design in place, we can invoke the initiate method as many times as needed, provided we've marked our data as retriable. Additional functionalities like automatic retries and operation cancellation can also be seamlessly integrated using the same approach.`}</p>
    <p>{`But that's not all. The boilerplate code within components still bothers me, and error handling isn't yet fully enforced. Let's tackle this issue from a different angle by introducing a component pattern for our application. Our goal is to create a generic component that leverages AsyncData to manage flow control.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AsyncData `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./AsyncData'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Spinner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Alert `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./YourProjectDefaultComponents'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` observer `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mobx-react-lite'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// here we have a little bit of typescript magic`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// this type maps type of the Data by having array of  AsyncData<TData> as input`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`InferSuccessData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`TData `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`extends`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`readonly`}</span>{` AsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`T`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`extends`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`readonly`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`unknown`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`readonly`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`P`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`keyof`}</span>{` TData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`P`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`infer`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`R`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`R`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`never`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AsyncDataProp`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`T`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`extends`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`readonly`}</span>{` `}<span parentName="span" {...{
              "className": "token builtin"
            }}>{`unknown`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` TDataCollection `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`extends`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`readonly`}</span>{` AsyncData`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`T`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token builtin"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    asyncData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TDataCollection
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` InferSuccessData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TDataCollection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ReactElement
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// component works with array (actually tuple) of AsyncData`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// makes standard logic of checking all the states and render default components`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// on success case it delegates rendering to children function`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token generic-function"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`AsyncDataPatternRaw`}</span><span parentName="span" {...{
              "className": "token generic class-name"
            }}><span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span>{`
	TValues `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`extends`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`readonly`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`unknown`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
	TDataCollection `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`extends`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`readonly`}</span>{` AsyncData`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`<`}</span>{`TValues`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token builtin"
              }}>{`number`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` asyncData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` children `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` AsyncDataProp`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TValues`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TDataCollection`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`asyncData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'loading'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Spinner`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Please wait while all the data is loading`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Spinner`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`asyncData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`kind `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'error'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Alert`}</span></span>{`
	            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`message`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Error loading data`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
	            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`description`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Something went wrong while loading the data. Please try to refresh the page.`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
	            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`error`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
	        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`asyncData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`manuallyRetriable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
                `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
                    `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` asyncData`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`filter`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`d `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` d`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`state`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`kind `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`===`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'error'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`forEach`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`d `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` d`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`initiate`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
				`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
					Retry
                `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
		`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
		`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`asyncData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`unknown`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` InferSuccessData`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`TDataCollection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TValues`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` AsyncDataPattern `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`observer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`AsyncDataPatternRaw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`And component can be used as next.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AsyncDataPattern `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./AsyncDataPattern'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`MyComponent`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`AsyncDataPattern`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`asyncData`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`myStore1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`someLoading`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` myStore2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`someUpdate`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`as`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
		`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`data1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` data2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
			`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
				`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`data1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
				`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`data2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
			`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
	`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`AsyncDataPattern`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`As you can see we left as minimum boilerplate in component as possible, and also we have enforced error handling and included default option to retry user actions if some of them failed.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The pattern of thinking presented in this article should helps to build better and scalable applications.`}</p>
    <p>{`Main takes are next:`}</p>
    <ol>
      <li parentName="ol">{`Think of what primitives could be extracted and re-used across application.`}</li>
      <li parentName="ol">{`As soon as you have such primitives, design them following approach described in the article`}
        <ol parentName="li">
          <li parentName="ol">{`Start from defining a problem statement`}</li>
          <li parentName="ol">{`Start from clear goals for design to solve defined problems`}</li>
          <li parentName="ol">{`Analyze existing approaches and tools to solve the problem. If there is no such - design your own.`}</li>
          <li parentName="ol">{`Start from domain modelling as pure as possible, ideally only language abilities. Try to model domain in a way that it is impossible to represent invalid state.`}</li>
          <li parentName="ol">{`Then move by the layer of abstractions higher (Mobx -> react), until last one is not reached.`}</li>
        </ol>
      </li>
    </ol>
    <p>{`Good design should allow to add new features and change existing ones with minimal effort.`}</p>
    <p>{`It should be easy to understand and maintain.`}</p>
    <p>{`It should be easy to test and debug. It should be easy to scale and extend.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      